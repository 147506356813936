<template>
    <div class="flex justify-center">
        <img src="@/assets/img/phone_outline.png" class="absolute w-full bottom-0 z-0" />
        <div class="screen relative overflow-hidden">
            <component v-bind:is="screen"></component>
        </div>
    </div>
</template>

<script>
import {
    mapActions,
} from 'vuex';

import SplashScreen from './SplashScreen.vue';
import LaunchScreen from './LaunchScreen.vue';
import MainScreen from './MainScreen.vue';

export default {
    name: 'SimulatorBase',

    props: {
        screen: {
            type: String,
            default: () => 'SplashScreen',
        },
    },

    beforeDestroy() {
        this.endSimulatorSession();
    },

    methods: {
        ...mapActions('simulator', [
            'endSimulatorSession',
        ]),
    },

    components: {
        SplashScreen,
        LaunchScreen,
        MainScreen,
    },
};
</script>

<style lang="postcss" scoped>
.screen {
    height: 93%;
    width: 90%;
    top: 30px;
    left: -1px;
    border-radius: 27px;
}
</style>
