<template>
    <div class="h-full relative">
        <NotificationBar :theme="notificationBarTheme" />
        <HomeScreen
            v-if="activeScreen === 'homeScreen'"
            :launchApplication="launchApplication"
        />
        <CallingScreen
            v-if="activeScreen === 'callScreen'"
            :goHome="goHome"
        />
        <MessagingScreen
            v-if="activeScreen === 'messageScreen'"
            :goHome="goHome"
        />
        <PaymentsScreen
            v-if="activeScreen === 'paymentsScreen'"
            :goHome="goHome"
        />
    </div>
</template>

<script>
export default {
    name: 'MainScreen',

    data() {
        return {
            activeScreen: 'homeScreen',
            notificationBarTheme: 'transparent',
        };
    },

    methods: {
        launchApplication(application) {
            switch (application) {
            case 'calling':
                this.setActiveScreen('callScreen', {
                    notificationBarTheme: 'white',
                });
                break;
            case 'messaging':
                this.setActiveScreen('messageScreen', {
                    notificationBarTheme: 'white',
                });
                break;
            case 'payments':
                this.setActiveScreen('paymentsScreen', {
                    notificationBarTheme: 'white',
                });
                break;
            default:
                this.setActiveScreen('homeScreen', {
                    notificationBarTheme: 'transparent',
                });
            }
        },

        setActiveScreen(screen, opts) {
            this.activeScreen = screen;
            this.notificationBarTheme = opts.notificationBarTheme;
        },

        goHome() {
            this.setActiveScreen('homeScreen', {
                notificationBarTheme: 'transparent',
            });
        },
    },

    components: {
        NotificationBar: () => import('./NotificationBar.vue'),
        HomeScreen: () => import('./HomeScreen.vue'),
        CallingScreen: () => import('./calling/Calling.vue'),
        MessagingScreen: () => import('./messaging/Messaging.vue'),
        PaymentsScreen: () => import('./payments/Payments.vue'),
    },
};
</script>
