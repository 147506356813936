<template>
    <div class="h-full w-full flex justify-center items-center bg-ide-gray">
        <form class="w-64 px-3 mt-12 relative -top-12" @submit.prevent="connect">
            <div class="flex mb-6">
                <VueTelInput
                    v-model.trim="phoneNumber"
                    placeholder="Enter phone number"
                    wrapperClasses="sim-tel"
                    inputClasses="sim-tel-input"
                    mode="international"
                />
            </div>
            <div
                class="py-2 px-4 bg-red-200 -mt-3 mb-6 text-sm text-red-900 rounded"
                v-if="validationError || connectError">
                {{ validationError ? validationError : connectError }}
            </div>
            <button
                type="submit"
                :class="{ 'cursor-wait': connecting === true }"
                class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                {{ connecting ? 'Connecting...' : 'Connect' }}
            </button>
        </form>
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
    mapMutations,
} from 'vuex';

import {
    VueTelInput,
} from 'vue-tel-input';

import {
    validatePhoneNumber,
} from '../../utils/validators';

export default {
    name: 'LaunchScreen',

    data() {
        return {
            phoneNumber: '',
            validationError: '',
            connecting: false,
        };
    },

    computed: {
        ...mapState('simulator', {
            simulatorPhoneNumber: 'phoneNumber',
            connectError: 'connectError',
            connected: 'connected',
        }),
    },

    watch: {
        connectError(newValue) {
            if (newValue) {
                this.connecting = false;
            }
        },
    },

    beforeDestroy() {
        this.setConnectError(null);
    },

    methods: {
        ...mapActions('simulator', [
            'startSimulatorSession',
        ]),

        ...mapMutations('simulator', [
            'setConnectError',
        ]),

        connect() {
            const strippedPhoneNumber = this.phoneNumber.replace(/\s/g, '');
            this.validationError = '';
            const validateError = validatePhoneNumber(strippedPhoneNumber);

            if (validateError) {
                this.validationError = validateError;
                return;
            }

            this.connecting = true;
            this.startSimulatorSession(strippedPhoneNumber);
        },
    },

    components: {
        VueTelInput,
    },
};
</script>

<style lang="postcss">
.sim-tel {
    @apply border-gray-600 relative;

    & .vti__dropdown {
        @apply absolute h-full;

        &:hover,
        &.open {
            @apply bg-ide-gray;
        }
    }

    & .vti__dropdown-list {
        @apply bg-ide-gray text-gray-100;
        width: 232px;

        & .vti__dropdown-item {
            padding: 4px 12px;
            font-size: 14px;

            &:hover,
            &.highlighted {
                @apply bg-ide-file;
            }
        }
    }
}

.vti__input.sim-tel-input {
    @apply bg-ide-gray-900 text-gray-100 w-full rounded border border-transparent py-3 px-4 leading-tight;
    padding-left: 60px;

    &:focus {
        @apply outline-none border-gray-600;
    }
}
</style>
