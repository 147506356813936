<template>
    <div class="h-full w-full flex justify-center items-center bg-ide-gray">
        <atEmblemSvg class="text-ide-gray-900 fill-current h-16" />
    </div>
</template>

<script>
import atEmblemSvg from '@/assets/img/atEmblem.svg';

export default {
    name: 'SplashScreen',

    components: {
        atEmblemSvg,
    },
};
</script>
