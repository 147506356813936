export function validatePhoneNumber(phoneNumber) {
    if (phoneNumber.length === 0) {
        return 'Phone Number is required';
    }

    if (!/^[0-9+]+$/i.test(phoneNumber)) {
        return 'Phone Number is invalid';
    }

    return null;
}

export { validatePhoneNumber as default };
